<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="changePasswordForm"
        ref="changePasswordForm"
        class="custom-form pt-8"
        @submit.prevent="changePassword()"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nowe hasło"
              v-model="password"
              :rules="[rules.required, rules.password]"
              validate-on-blur
              placeholder="Wpisz nowe hasło"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="changePasswordForm"
      >
        Zapisz zmianę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      password: null
    }
  },
  computed: {
    ...mapState({
      id: state => state.singleView.entity.id,
      tableName: state => state.singleView.tableName,
      isProcessing (state) {
        return state[this.tableName].isProcessing
      }
    })
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      editItem (dispatch, payload) {
        return dispatch(`${this.tableName}/editItem`, payload)
      },
    }),
    changePassword () {
      if (this.$refs.changePasswordForm.validate()) {
        const { id, tableName, password } = this

        this.editItem({
          tableName,
          params: { password },
          notWebSocketHandled: true,
          id
        }).then(() => { this.closeDialog() })
      }
    }
  }
}
</script>
